<template>
  <div>
    <el-timeline-item
      v-if="content"
      :icon="icon"
      :type="type"
      :color="color"
      size="large"
      :timestamp="formatDate(activity.createdOn)"
    >
      <div v-html="formattedActivityText" class=""></div>
    </el-timeline-item>
  </div>
</template>

<script>
import ActivitiesTimelineMixin from "@/components/ActivitiesView/ActivitiesTimelineMixin.vue";
export default {
  mixins: [ActivitiesTimelineMixin],
  props: {
    activity: {
      type: Object,
      required: true
    },
    accreditedBuilder: {
      type: Object,
      required: false
    }
  },
  methods: {
    async generateReadableUpdateQuote() {
      let contentHtml = ``;
      for (let update of this.updates) {
        contentHtml = this.generateRegularActivityString(contentHtml, update);
      }
      this.setReadableUpdateProps(contentHtml);
    }
  },
  watch: {
    activity: {
      async handler() {
        this.content = "";
        this.icon = "";
        this.type = "";
        this.link = {};
        this.color = "";
        switch (this.activity.name) {
          case "ADD_ACCREDITED_BUILDER_SUCCESS":
            this.generateReadableGeneric({
              content: `created an accredited builder called ${this.activity
                .data?.builderName || ""} `,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_ACCREDITED_BUILDER_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to add an accredited builder.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_ACCREDITED_BUILDER_SUCCESS":
            await this.generateReadableUpdateQuote();
            break;
          case "UPDATE_ACCREDITED_BUILDER_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to update accredited builder.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_ACCREDITED_BUILDER_SUCCESS":
            this.generateReadableGeneric({
              content: `deleted an accredited builder called ${this.activity
                .data?.builderName || ""}`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_ACCREDITED_BULDER_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to delete accredited builder`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
        }
      },
      immediate: true
    }
  }
};
</script>

<style></style>
