
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import AccreditedBuilderActivitiesTimelineItem from "./AccreditedBuilderActivitiesTimelineItem.vue";
interface IAccreditedBuilderActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "accreditedBuilderActivitiesView",
  components: { AccreditedBuilderActivitiesTimelineItem },
  mixins: [ActivitiesMixin],

  data(): IAccreditedBuilderActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
          ADD_ACCREDITED_BUILDER_FAILURE,
          ADD_ACCREDITED_BUILDER_SUCCESS,
          UPDATE_ACCREDITED_BUILDER_FAILURE,
          UPDATE_ACCREDITED_BUILDER_SUCCESS,
          DELETE_ACCREDITED_BUILDER_SUCCESS,
          DELETE_ACCREDITED_BUILDER_FAILURE
          `.replace(/\s/g, ""),
        activityType: "AccreditedBuilderActivity"
      };
    }
  }
});
